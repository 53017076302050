<template>
  <v-container>
    <v-card elevation="0" class="indigo" style="background: linear-gradient(45deg, #03A9F4, #0000007d);">
      <v-card-title class="">
        <span>{{ title }}</span>
      </v-card-title>
    </v-card>
    <v-tabs v-model="tab" align-with-title>
      <v-tabs-slider color="yellow"></v-tabs-slider>
      <template v-for="item in tabs">
        <v-tab :key="item.text" v-if="item.hidden">
          {{ item.text }}
        </v-tab>
      </template>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-row no-gutters>
          <v-col cols="12" sm="2" offset="1">
            <v-sheet min-height="80" class=" lighten-5 d-flex align-center justify-center">
              <v-file-input hide-input accept="image/*" :rules="rules.fileRules" prepend-icon="mdi-camera"
                class="grey lighten-4 pt-0 mt-9 justify-center" v-model="avatarPhoto" style="z-index:1000;opacity: 0.7;"
                @change="uploadAvatarPhoto()" :value="avatarPhoto"></v-file-input>

              <v-img :src="form.avatar" max-height="65" max-width="65" style="position:absolute;">
              </v-img>
            </v-sheet>
          </v-col>
          <v-col cols="12" sm="5">
            <span class="headline">{{ form.username }}</span>
          </v-col>
        </v-row>
        <v-form ref="form" v-model="valid">
          <v-row dense>
            <v-col cols="12" sm="2" offset="1" class="text-center">
              <span class="primary--text">邮箱</span>
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field label="输入邮箱" dense solo v-model="form.email" :rules="rules.emailRules"></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" sm="2" offset="1" class="text-center">
              <span class="primary--text">手机号</span>
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field label="输入新手机号" dense solo v-model="form.phoneNumber" :rules="rules.phoneNumberRules">
              </v-text-field>
              <v-row dense>
                <v-col cols="12" sm="8">
                  <v-text-field label="验证码" dense solo v-model="form.verificationCode"
                    :rules="rules.verificationCodeRules"></v-text-field>
                </v-col>
                <v-col cols="12" sm="4" class="text-end">
                  <v-btn @click="getCode" :disabled="disable">{{
                    codeText
                  }}</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row dense v-if="role == 'projectAdmin' || role == 'customer'">
            <v-col cols="12" sm="2" offset="1" class="text-center">
              <span class="primary--text">邀请码</span>
            </v-col>
            <v-col cols="12" sm="5">
              <v-text-field label="邀请码" disabled dense solo v-model="invitationCode"></v-text-field>
            </v-col>
          </v-row>
          <v-row dense justify="center">
            <v-col cols="7" class="text-end">
              <v-btn color="teal lighten-2" @click="update">提交</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-tab-item>

      <v-tab-item v-if="authenticationHidden">
        <div class="mt-2">
          <v-row no-gutters class="mt-2">
            <v-col cols="12" sm="2" offset="1">
              认证状态：
            </v-col>
            <v-col cols="12" sm="8" :style="{ color: applyStatus }"><span>{{ status }}</span></v-col>
          </v-row>
          <div>
            <v-row no-gutters>
              <v-col cols="12" sm="2" offset="1" class="mt-5">账号类型 </v-col>
              <v-col cols="12" sm="8">
                <v-radio-group row dense v-model="cerForm.type">
                  <v-radio label="个人用户"></v-radio>
                  <v-radio label="企业用户"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-expand-transition>
              <v-form ref="cerForm">
                <v-row no-gutters v-if="cerForm.type == 0">
                  <v-col cols="12" sm="2" offset="1" class="mt-2">姓名</v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field solo dense v-model="cerForm.name" :rules="rules.nameRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" offset="1" class="mt-2">身份证号</v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field solo dense v-model="cerForm.uid" :rules="rules.idCardRules"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" offset="1" class="mt-2">上传照片</v-col>
                  <v-col cols="12" sm="4">
                    <v-sheet min-height="80" class="grey lighten-4 d-flex align-center justify-center">
                      <v-file-input hide-input accept="image/*" prepend-icon="mdi-plus-circle"
                        class="pt-0 mt-0 justify-center" v-model="cerForm.frontPhoto" style="z-index:1000"
                        @change="uploadFrontPhoto()" :rules="cerRules.frontPhotoRuler">
                      </v-file-input>
                      <v-img :src="frontPhotoUrl" max-height="65" max-width="65" style="position:absolute;"></v-img>
                    </v-sheet>
                    <p class="text-center caption">身份证正面照</p>
                  </v-col>
                  <v-col cols="12" sm="4" offset="1">
                    <v-sheet min-height="80" class="grey lighten-4 d-flex align-center justify-center">
                      <v-file-input hide-input accept="image/*" prepend-icon="mdi-plus-circle"
                        class="pt-0 mt-0 justify-center" v-model="cerForm.backPhoto" style="z-index:1000"
                        @change="uploadBackPhoto()" :rules="cerRules.backPhotoRuler">
                      </v-file-input>
                      <v-img :src="backPhotoUrl" max-height="65" max-width="65" style="position:absolute;"></v-img>
                    </v-sheet>
                    <p class="text-center caption">身份证反面照</p>
                  </v-col>
                </v-row>

                <v-row no-gutters v-if="cerForm.type == 1">
                  <v-col cols="12" sm="2" offset="1" class="mt-2">单位名</v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field solo dense v-model="cerForm.companyName" :rules="cerRules.companyNameRuler">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" offset="1" class="mt-2">工商执照</v-col>
                  <v-col cols="12" sm="8">
                    <v-text-field solo dense v-model="cerForm.businessLicense" :rules="cerRules.businessLicenseRuler">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2" offset="1" class="mt-2">上传照片</v-col>
                  <v-col cols="12" sm="4">
                    <v-sheet min-height="80" class="grey lighten-4 d-flex align-center justify-center">
                      <v-file-input hide-input accept="image/*" prepend-icon="mdi-plus-circle"
                        class="pt-0 mt-0 justify-center" v-model="cerForm.businessLicensePhoto" style="z-index:1000"
                        @change="uploadBusinessLicensePhoto()" :rules="cerRules.businessLicensePhotoRuler">
                      </v-file-input>
                      <v-img :src="businessLicensePhotoUrl" max-height="65" max-width="65" style="position:absolute;">
                      </v-img>
                    </v-sheet>
                    <p class="text-center caption">营业执照</p>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
            <v-row dense justify="center">
              <v-col cols="7" class="text-end">
                <v-btn color="teal lighten-2" @click="updateCer">提交</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-tab-item>

      <v-tab-item>
        <div class="mt-2">
          <v-form ref="password">
            <v-row dense>
              <v-col cols="12" sm="2" offset="1" class="text-center mt-2">
                <span class="primary--text">原密码</span>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field label="原密码" dense solo type="password" v-model="old_password"
                  :rules="rules.oldPasswordRules">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="2" offset="1" class="text-center">
                <span class="primary--text">新密码</span>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field label="新密码" dense solo type="password" v-model="new_password"
                  :rules="rules.passwordRules">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" sm="2" offset="1" class="text-center">
                <span class="primary--text">确认新密码</span>
              </v-col>
              <v-col cols="12" sm="5">
                <v-text-field label="确认新密码" dense solo type="password" v-model="confirm_password"
                  :rules="confirmPasswordRules">
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense justify="center">
              <v-col cols="7" class="text-end">
                <v-btn color="teal lighten-2" @click="updatePassword">保存</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
// let vm;
import axios from "axios"; //基于Promise的HTTP客户端
import rules from "../../config/rules";
//import qs from "qs"
import commonCfg from "../../config/common";
let commonUrl = commonCfg.commonUrl;
let version = commonCfg.urlVersion;
// let commonUrl = "http://192.168.1.104:64011/";
// let version = "";
let vm;
export default {
  data () {
    return {
      tab: null,
      title: "个人资料",
      authenticationHidden: true,
      tabs: [],
      valid: "",
      avatarPhoto: [], //头像
      // 短信验证码计时
      timer: null,
      disable: false,
      codeText: "获取验证码",
      second: 60,

      applyStatus: "blue-grey", // 认证字体颜色 ：blue-grey/red/green/orgin/
      status: "未认证", // 未认证/已认证/认证中/认证失败
      hidden: false, // 认证中隐藏表单
      backPhoto: [],
      frontPhotoUrl: require("../../assets/i/favicon.png"),
      backPhotoUrl: require("../../assets/i/favicon.png"),
      businessLicensePhotoUrl: require("../../assets/i/favicon.png"),

      // 修改密码
      old_password: "",
      new_password: "",
      confirm_password: "",
      //基础资料表单
      form: {
        username: "",
        phoneNumber: "",
        email: "",
        avatar: ""
      },
      invitationCode: "",
      // 身份认证表单
      cerForm: {
        uid: "",
        name: "",
        companyName: "",
        businessLicense: "",
        businessLicensePhoto: [],
        frontPhoto: [],
        backPhoto: [],
        type: 0 // 0-个人用户，1-企业用户
      },
      rules: rules.rules,
      cerRules: {
        nameRuler: [v => (this.cerForm.type == 0 && !!v) || "姓名为必填"],
        idCardRuler: [v => (this.cerForm.type == 0 && !!v) || "身份证为必填"],
        frontPhotoRuler: [
          v => (this.cerForm.type == 0 && v.length != 0) || "身份证正面照为必填"
        ],
        backPhotoRuler: [
          v => (this.cerForm.type == 0 && v.length != 0) || "身份证背面照为必填"
        ],

        companyNameRuler: [
          v => (this.cerForm.type == 1 && !!v) || "公司名为必填"
        ],
        businessLicenseRuler: [
          v => (this.cerForm.type == 1 && !!v) || "工商执照为必填"
        ],
        businessLicensePhotoRuler: [
          v => (this.cerForm.type == 1 && v.length != 0) || "工商执照为必填"
        ]
      },
      confirmPasswordRules: [
        v => !!v || "确认密码为必填",
        v => v === this.new_password || "密码不匹配"
      ]
    };
  },
  destroyed () {
    //销毁定时器
    clearInterval(this.timer);
  },
  beforeRouteLeave: function (to, from, next) {
    clearInterval(this.timer);
    next();
  },
  created () {
    this.authenticationHidden =
      this.role == "customer" || this.role == "projectAdmin";
    this.tabs = [
      {
        text: "基础资料",
        hidden: true
      },
      {
        text: "身份认证",
        hidden: this.authenticationHidden
      },
      {
        text: "修改密码",
        hidden: true
      }
    ];
    this.getUserInfo();

    vm = this;
  },
  props: ["role", "username"],
  methods: {
    /**
     * 获取验证码
     */
    getCode () {
      if (this.form.phoneNumber.length == 11) {
        this.countDownTimer();
        let formData = new FormData();
        formData.append("mobile", this.form.phoneNumber);
        axios
          .post(`${commonUrl}pushSMS${version}`, formData)
          .then(res => {
            console.log(res);
            if (res.data.code == 200) {
              this.$emit("tips", "获取验证码成功", "green");
            } else if (res.data.message != null) {
              this.$emit("tips", res.data.message, "red");
            } else {
              this.$emit("tips", res.data.message, "red");
            }
          })
          .catch(e => {
            console.log(e);
            this.$emit("tips", "获取验证码失败", "red");
          });
      } else {
        this.$emit("tips", "手机号无效", "red");
      }
    },
    // 重新获取验证码计时
    countDownTimer () {
      this.timer = setInterval(function () {
        vm.second -= 1;
        if (vm.second > 0) {
          vm.codeText = "重新发送(" + vm.second + ")";
          vm.disable = true;
        } else {
          vm.second = 60;
          clearInterval(vm.timer);
          vm.codeText = "获取验证码";
          vm.disable = false;
        }
      }, 1000);
    },
    // 获取当前用户信息
    getUserInfo () {
      axios.get(`${commonUrl}listOneUser${version}`).then(res => {
        console.log(res);
        this.form.username = res.data.result.username;
        this.form.phoneNumber = res.data.result.phoneNumber;
        this.form.email = res.data.result.email;
        this.form.avatar = res.data.result.avatar;
        if (this.role == "projectAdmin" || this.role == "customer") {
          this.invitationCode = res.data.result.invitationCode;
        }
        switch (res.data.result.applyStatus) {
          case "pass":
            this.applyStatus = "green";
            this.status = "已认证";
            this.hidden = false;
            break;
          case "checking":
            this.applyStatus = "orange";
            this.status = "认证中";
            this.hidden = true;
            break;
          case "refuse":
            this.applyStatus = "red";
            this.status = "认证失败";
            this.hidden = false;
            break;
          default:
            this.applyStatus = "blue-grey";
            this.status = "未认证";
            this.hidden = false;
        }
      });
    },

    // 修改基础资料
    update () {
      console.log(this.form);
      if (this.$refs.form.validate()) {
        axios.post(`${commonUrl}editUser${version}`, this.form).then(res => {
          console.log(res);
          if (res.data.code == 200) {
            this.$emit("tips", "修改成功", "green");
          } else if (res.data.message != null) {
            this.$emit("tips", res.data.message, "red");
          } else {
            this.$emit("tips", res.data.message, "red");
          }
        });
      }
    },
    // 更换头像并显示
    uploadAvatarPhoto () {
      if (this.avatarPhoto.size > 1024 * 1024 * 2) {
        this.$emit("tips", "文件大小超过2M", "red");
        return;
      }
      this.form.avatar = this.avatarPhoto;
      var reader = new FileReader();
      reader.readAsDataURL(this.avatarPhoto);
      reader.onload = function () {
        console.log(reader.result);
        console.log(reader);
        //直接使用读取的结果
        vm.form.avatar = reader.result;
      };
    },
    // 更换身份证正面照片
    uploadFrontPhoto () {
      if (this.cerForm.frontPhoto.size > 1024 * 1024 * 2) {
        this.$emit("tips", "文件大小超过2M", "red");

        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.cerForm.frontPhoto);
      reader.onload = function () {
        console.log(reader.result);
        console.log(reader);
        //直接使用读取的结果
        vm.frontPhotoUrl = reader.result;
      };
    },
    // 更换身份证背面照片
    uploadBackPhoto () {
      if (this.cerForm.backPhoto.size > 1024 * 1024 * 2) {
        this.$emit("tips", "文件大小超过2M", "red");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.cerForm.backPhoto);
      reader.onload = function () {
        console.log(reader.result);
        console.log(reader);
        //直接使用读取的结果
        vm.backPhotoUrl = reader.result;
      };
    },
    // 更新营业执照
    uploadBusinessLicensePhoto () {
      if (this.cerForm.businessLicensePhoto.size > 1024 * 1024 * 2) {
        this.$emit("tips", "文件大小超过2M", "red");
        return;
      }
      var reader = new FileReader();
      reader.readAsDataURL(this.cerForm.businessLicensePhoto);
      reader.onload = function () {
        console.log(reader.result);
        console.log(reader);
        //直接使用读取的结果
        vm.businessLicensePhotoUrl = reader.result;
      };
    },
    // 修改密码
    updatePassword () {
      if (this.$refs.password.validate()) {
        let param = {
          newPws: this.new_password,
          oldPws: this.old_password,
          username: this.form.username
        };
        axios.post(`${commonUrl}editUserPws${version}`, param).then(res => {
          console.log(res);
          if (res.data.code == 200) {
            this.$emit("tips", "修改成功", "green");
          } else if (res.data.message != null) {
            this.$emit("tips", res.data.message, "red");
          } else {
            this.$emit("tips", "修改失败", "red");
          }
        });
      }
    },
    // 提交身份认证
    updateCer () {
      if (this.$refs.cerForm.validate()) {
        this.cerForm.username = this.form.username;
        var formData = new FormData();
        formData.append("username", this.cerForm.username);
        formData.append("email", this.form.email);
        formData.append("phoneNumber", this.form.phoneNumber);
        formData.append("type", this.cerForm.type);
        if (this.cerForm.type == 0) {
          formData.append("frontPhoto", this.cerForm.frontPhoto);
          formData.append("backPhoto", this.cerForm.backPhoto);
          formData.append("uid", this.cerForm.uid);
          formData.append("name", this.cerForm.name);
        } else {
          formData.append(
            "businessLicensePhoto",
            this.cerForm.businessLicensePhoto
          );
          formData.append("businessLicense", this.cerForm.businessLicense);
          formData.append("companyName", this.cerForm.companyName);
        }
        axios.post(`${commonUrl}applyAuth${version}`, formData).then(res => {
          if (res.data.code == 200) {
            this.$emit("tips", "提交成功", "green");
          } else if (res.data.message != null) {
            this.$emit("tips", res.data.message, "red");
          } else {
            this.$emit("tips", "提交失败", "red");
          }
        });
      }
    }
  }
};
</script>
